import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import accountIndustriesReducer from './slices/accountIndustries';
import accountTypesReducer from './slices/accountTypes';
import insuranceReducer from './slices/insurance';
import pipelineReducer from './slices/pipeline';
import documentsReducer from './slices/documents';
import alexReducer from './slices/alex';
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import configurationsReducer from './slices/configurations';
import productReducer from './slices/product';
import partsReducer from './slices/part';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import requestReducer from './slices/request';
import ratesReducer from './slices/rate';
import costsReducer from './slices/cost';
import rateSetupWizardReducer from './slices/rateSetupWizard';
import claimsReducer from './slices/claims';
import companyReducer from './slices/company';
import labelReducer from './slices/label';
import rolesReducer from './slices/role';
import packagesReducer from './slices/package';
import servicesReducer from './slices/service';
import vehiclesReducer from './slices/vehicle';
import hooReducer from './slices/hoo';
import appAssetsReducer from './slices/appAssets';
import locationReducer from './slices/location';
import signalRReducer from './slices/signalR';
import alertsReducer from './slices/alerts';
import organizationReducer from './slices/organization';
import workspacesReducer from './slices/workspaces';
import sessionReducer from './slices/session';
import navigationReducer from './slices/navigation';
import contentReducer from './slices/content';
import integrationReducer from './slices/integration';
import facilityReducer from './slices/facility';
import jobsReducer from './slices/jobs';
import environmentReducer from './slices/environment';
import payrollReducer from './slices/payroll';
import scriptReducer from './slices/script';
import systemReducer from './slices/system';
import usersReducer from './slices/users';
import settingsReducer from './slices/settings';

// ----------------------------------------------------------------------

const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: [],
};

const productPersistConfig = {
    key: 'product',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
    insurances: insuranceReducer,
    settings: settingsReducer,
    users: usersReducer,
    system: systemReducer,
    script: scriptReducer,
    payroll: payrollReducer,
    jobs: jobsReducer,
    environment: environmentReducer,
    facilities: facilityReducer,
    documents: documentsReducer,
    integrations: integrationReducer,
    accountTypes: accountTypesReducer,
    pipeline: pipelineReducer,
    accountIndustries: accountIndustriesReducer,
    alex: alexReducer,
    configurations: configurationsReducer,
    navigation: navigationReducer,
    mail: mailReducer,
    parts: partsReducer,
    labels: labelReducer,
    rates: ratesReducer,
    costs: costsReducer,
    content: contentReducer,
    workspaces: workspacesReducer,
    organization: organizationReducer,
    session: sessionReducer,
    chat: chatReducer,
    calendar: calendarReducer,
    companies: companyReducer,
    kanban: kanbanReducer,
    request: requestReducer,
    claims: claimsReducer,
    roles: rolesReducer,
    alerts: alertsReducer,
    hoos: hooReducer,
    locations: locationReducer,
    rateSetupWizard: rateSetupWizardReducer,
    signalR: signalRReducer,
    services: servicesReducer,
    vehicles: vehiclesReducer,
    appAssets: appAssetsReducer,
    packages: packagesReducer,
    product: persistReducer(productPersistConfig, productReducer),
});

export {rootPersistConfig, rootReducer};
