import {alpha} from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
    return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const PRIMARY = {
    lighter: '#e0deff',
    light: '#a39fe1',
    main: '#5f57e4',
    dark: '#4439ea',
    darker: '#342cb5',
};
const SECONDARY = {
    lighter: '#d6feff',
    light: '#b8fdff',
    main: '#03ccd4',
    dark: '#017b80',
    darker: '#014948',
};
const INFO = {
    lighter: '#D0F2FF',
    light: '#74CAFF',
    main: '#1890FF',
    dark: '#0C53B7',
    darker: '#04297A',
};
const SUCCESS = {
    lighter: '#E9FCD4',
    light: '#AAF27F',
    main: '#54D62C',
    dark: '#229A16',
    darker: '#08660D',
};
const WARNING = {
    lighter: '#FFF7CD',
    light: '#FFE16A',
    main: '#FFC107',
    dark: '#B78103',
    darker: '#7A4F01',
};
const ERROR = {
    lighter: '#FFE7D9',
    light: '#FFA48D',
    main: '#FF4842',
    dark: '#B72136',
    darker: '#7A0C2E',
};

const BLACK = '#223354';

const WHITE = '#ffffff';

const GREY = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
    500_8: alpha('#919EAB', 0.08),
    500_12: alpha('#919EAB', 0.12),
    500_16: alpha('#919EAB', 0.16),
    500_24: alpha('#919EAB', 0.24),
    500_32: alpha('#919EAB', 0.32),
    500_48: alpha('#919EAB', 0.48),
    500_56: alpha('#919EAB', 0.56),
    500_80: alpha('#919EAB', 0.8),
};

const GRADIENTS = {
    primary: createGradient(PRIMARY.light, PRIMARY.main),
    info: createGradient(INFO.light, INFO.main),
    success: createGradient(SUCCESS.light, SUCCESS.main),
    warning: createGradient(WARNING.light, WARNING.main),
    error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
    violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
    blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
    green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
    yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
    orange: ['#FFA500', '#FFB347', '#FFD700', '#FFE4B5'],
    red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const ICON_COLORS = {
    violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
    blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
    green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
    orange: ['#FFA500', '#FFB347', '#FFD700', '#FFE4B5'],
    red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
    pink: ['#FF5CA5', '#FF8CC7', '#FFB3DC', '#FFD1E9'],
    teal: ['#00BFA6', '#4DDDC1', '#88F3DB', '#BFFCEF'],
    lime: ['#A8E60C', '#C5F254', '#D8FF9E', '#EBFFD2'],
    coral: ['#FF785C', '#FF9B7A', '#FFC4A0', '#FFE6CD'],
    indigo: ['#5C6BC0', '#7986CB', '#9FA8DA', '#C5CAE9'],
    navy: ['#2A3F5F', '#3F567D', '#68789E', '#9DA8BC'],
    brown: ['#8D6E63', '#A1887F', '#D7CCC8', '#EFEBE9'],
    cyan: ['#00BCD4', '#26C6DA', '#80DEEA', '#B2EBF2'],
    gold: ['#FFD700', '#FFEB3B', '#FFF59D', '#FFFCDF'],
    lavender: ['#E0B0FF', '#CDA4DE', '#D8BFD8', '#E6E6FA'],
    mint: ['#98FF98', '#B2FBA5', '#CCFFCC', '#E6FFE6'],
    maroon: ['#800000', '#A52A2A', '#B22222', '#D2691E'],
    peach: ['#FFB347', '#FFCC99', '#FFDAB9', '#FFE5B4'],
    olive: ['#808000', '#A9A90D', '#B5B55C', '#CDCD77'],
    plum: ['#DDA0DD', '#E6A8D7', '#E0B0FF', '#D8BFD8'],
    sky: ['#87CEEB', '#ADD8E6', '#B0E2FF', '#C0EFFF'],
    periwinkle: ['#CCCCFF', '#B0C4DE', '#C3C3E5', '#D6D6F3'],
    mustard: ['#FFDB58', '#E1AD01', '#FFD34E', '#FFE066'],
    charcoal: ['#36454F', '#3E4A59', '#555E6C', '#6E737E'],
    turquoise: ['#40E0D0', '#48D1CC', '#76E4F7', '#B0E9FF'],
    magenta: ['#FF00FF', '#FF77FF', '#FF99CC', '#FFCCE5'],
    sage: ['#B2AC88', '#C1BC9A', '#D2CEB6', '#E0E4C9'],
    raspberry: ['#E30B5D', '#D52A5D', '#E75480', '#F1A7B5'],
    cobalt: ['#0047AB', '#3366CC', '#5A8DFF', '#8CAFFF'],
    khaki: ['#F0E68C', '#F5DEB3', '#EEDD82', '#FFFACD'],
    jade: ['#00A86B', '#1FA78D', '#4CAF50', '#77DD77'],
    silver: ['#C0C0C0', '#D3D3D3', '#E0E0E0', '#F8F8F8'],
    apricot: ['#FBCEB1', '#FFDAB9', '#FFCBA4', '#FFD3B6'],
    amber: ['#FFBF00', '#FFC107', '#FFD700', '#FFF8DC'],
    emerald: ['#50C878', '#6AB97E', '#7DDC85', '#A4F9B8'],
};


const COMMON = {
    white: '#fff',
    black: '#000',
    common: {black: '#000', white: '#fff'},
    primary: {...PRIMARY, contrastText: '#fff'},
    secondary: {...SECONDARY, contrastText: '#fff'},
    info: {...INFO, contrastText: '#fff'},
    success: {...SUCCESS, contrastText: GREY[800]},
    warning: {...WARNING, contrastText: GREY[800]},
    error: {...ERROR, contrastText: '#fff'},
    grey: GREY,
    gradients: GRADIENTS,
    chart: CHART_COLORS,
    icon: ICON_COLORS,
    divider: GREY[500_24],
    alpha: {
        white: {
            5: alpha(WHITE, 0.02),
            10: alpha(WHITE, 0.1),
            30: alpha(WHITE, 0.3),
            50: alpha(WHITE, 0.5),
            70: alpha(WHITE, 0.7),
            100: '#fff'
        },
        trueWhite: {
            5: alpha(WHITE, 0.02),
            10: alpha(WHITE, 0.1),
            30: alpha(WHITE, 0.3),
            50: alpha(WHITE, 0.5),
            70: alpha(WHITE, 0.7),
            100: '#fff'
        },
        black: {
            5: alpha(BLACK, 0.02),
            10: alpha(BLACK, 0.1),
            30: alpha(BLACK, 0.3),
            50: alpha(BLACK, 0.5),
            70: alpha(BLACK, 0.7),
            100: '#000'
        }
    },
    action: {
        hover: GREY[500_8],
        selected: GREY[500_16],
        disabled: GREY[500_80],
        disabledBackground: GREY[500_24],
        focus: GREY[500_24],
        hoverOpacity: 0.08,
        disabledOpacity: 0.48,
    },
};

const palette = {
    light: {
        ...COMMON,
        mode: 'light',
        text: {primary: GREY[800], secondary: GREY[600], disabled: GREY[500]},
        background: {paper: '#fff', default: '#fff', neutral: GREY[200]},
        action: {active: GREY[600], ...COMMON.action},
    },
    dark: {
        ...COMMON,
        mode: 'dark',
        text: {primary: '#fff', secondary: GREY[500], disabled: GREY[600]},
        background: {paper: GREY[800], default: GREY[900], neutral: GREY[500_16]},
        action: {active: GREY[500], ...COMMON.action},
    },
};

export default palette;
