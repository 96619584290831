import _ from 'lodash';

export const generatePhoneNumber = (number, country, countryCode, friendlyName) => {
    if (!_.isEmpty(number?.trim())) {

        const friendlyNameSection = _.isEmpty(friendlyName?.trim()) ? "" :
            `${friendlyName} `;

        const countrySection = _.isEmpty(countryCode?.trim()) ? "" :
            `(${country}): `;

        const countryCodeSection = _.isEmpty(countryCode?.trim()) ? "" :
            `+${countryCode} `;

        return `${friendlyNameSection}${countrySection}${countryCodeSection}${number}`
    }
}