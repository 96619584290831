import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    calendars: [],
    selectedCalendarId: null,
    events: [],
    isOpenModal: false,
    isOpenCalendarModal: false,
    selectedEventId: null,
    selectedRange: null,
};

const slice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET EVENTS
        getEventsSuccess(state, action) {
            state.isLoading = false;
            state.events = action.payload;
        },

        // SET SELECTED CALENDAR
        setSelectedCalendarSuccess(state, action) {
            const _id = action.payload;
            state.selectedCalendarId = _id;
            window.localStorage.setItem('selectedCalendarId', _id)
        },

        // GET EVENTS
        getCalendarsSuccess(state, action) {
            state.isLoading = false;
            state.calendars = action.payload;
            if (action.payload.length === 1) {
                state.selectedCalendarId = action.payload[0]._id
                window.localStorage.setItem('selectedCalendarId', action.payload[0]._id)
            } else if (window.localStorage.getItem('selectedCalendarId')) {
                state.selectedCalendarId = window.localStorage.getItem('selectedCalendarId');

            } else if (action.payload.length > 1) {
                state.selectedCalendarId = action.payload[0]._id
                window.localStorage.setItem('selectedCalendarId', action.payload[0]._id)
            }
        },

        // GET EVENTS
        creatCalendarSuccess(state, action) {
            state.isLoading = false;
            const newCalendar = action.payload;
            const newCalendars = [...state.calendars, newCalendar];
            state.calendars = newCalendars
            if (newCalendars.length === 1) {
                state.selectedCalendarId = newCalendars[0]._id
            }
        },

        // CREATE EVENT
        createEventSuccess(state, action) {
            const newEvent = action.payload;
            state.isLoading = false;
            state.events = [...state.events, newEvent];
        },

        // UPDATE CALENDAR
        updateCalendarSuccess(state, action) {

        },

        // UPDATE EVENT
        updateEventSuccess(state, action) {
            const event = action.payload;
            const updateEvent = state.events.map((_event) => {
                if (_event._id === event._id) {
                    return event;
                }
                return _event;
            });

            state.isLoading = false;
            state.events = updateEvent;
        },

        // DELETE EVENT
        deleteEventSuccess(state, action) {
            const {eventId} = action.payload;
            const deleteEvent = state.events.filter((event) => event._id !== eventId);
            state.events = deleteEvent;
        },

        // DELETE EVENT
        deleteCalendarSuccess(state, action) {
            const {calendarId} = action.payload;
            const deleteCalendars = state.calendars.filter((calendar) => calendar._id !== calendarId);
            state.selectedCalendarId = deleteCalendars[0]._id
            window.localStorage.setItem('selectedCalendarId', deleteCalendars[0]._id)
            state.calendars = deleteCalendars;
        },

        // SELECT EVENT
        selectEvent(state, action) {
            const eventId = action.payload;
            state.isOpenModal = true;
            state.selectedEventId = eventId;
        },

        // SELECT RANGE
        selectRange(state, action) {
            const {start, end} = action.payload;
            state.isOpenModal = true;
            state.selectedRange = {start, end};
        },

        // OPEN MODAL
        openModal(state) {
            state.isOpenModal = true;
        },

        // OPEN CALENDAR MODAL
        openCalendarModal(state) {
            state.isOpenCalendarModal = true;
        },

        // CLOSE MODAL
        closeModal(state) {
            state.isOpenModal = false;
            state.selectedEventId = null;
            state.selectedRange = null;
        },

        // CLOSE CALENDAR MODAL
        closeCalendarModal(state) {
            state.isOpenCalendarModal = false;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {openModal, closeModal, selectEvent, openCalendarModal, closeCalendarModal} = slice.actions;

// ----------------------------------------------------------------------

export function setSelectedCalendar(calendarId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.setSelectedCalendarSuccess(calendarId));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getCalendars() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/calendars`);
            dispatch(slice.actions.getCalendarsSuccess(response.data.calendars));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getEvents(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/calendars/${id}/events`);
            dispatch(slice.actions.getEventsSuccess(response.data.events));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function createCalendar(newCalendar) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put('/api/calendars', {calendars: [newCalendar]});
            dispatch(slice.actions.creatCalendarSuccess(response.data.calendars[0]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function createEvent(newEvent, calendarId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(
                '/api/calendars/events',
                {
                    events: [{...newEvent, calendarId}]
                }
            )
            dispatch(slice.actions.createEventSuccess(response.data.events[0]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateEvent(eventId, updateEvent, calendarId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/api/calendars/events/${eventId}`, {
                events: [{
                    ...updateEvent,
                    calendarId
                }]
            });
            dispatch(slice.actions.updateEventSuccess(response.data.events[0]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateCalendar(_id, updateCalendar) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/api/calendars/${_id}`, {
                ...updateCalendar
            });
            dispatch(slice.actions.updateCalendarSuccess(response.data.calendars[0]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function deleteEvent(eventId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.delete(`/api/calendars/events?events=${eventId}`);
            dispatch(slice.actions.deleteEventSuccess({eventId}));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function deleteCalendar(calendarId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.delete(`/api/calendars?calendars=${calendarId}`);
            dispatch(slice.actions.deleteCalendarSuccess({calendarId}));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function selectRange(start, end) {
    return async () => {
        dispatch(
            slice.actions.selectRange({
                start: start?.getTime(),
                end: end?.getTime(),
            })
        );
    };
}
