// translationUtil.js

export const translateNavConfig = (config, translate) => {
    return config.map((section) => ({
        ...section,
        subheader: translate(`navs.sink.subheaders.${section?.subheader?.toLowerCase()}`),
        items: section?.items?.map((item) => ({
            ...item,
            title: translate(`navs.sink.titles.${item?.title?.toLowerCase()}`)
        }))
    }));
};


export const translateReportHeaderNames = (config, translate) => {
    return config.map((column) => ({
        ...column,
        headerName: translate(column.headerName),
    }))
}