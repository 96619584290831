import {createContext, useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
// hooks
// utils
import {defaultPreset} from '../utils/getColorPresets';
// config
import {ALERT_TYPES, defaultSettings} from '../config';
import useSignalR from "../hooks/useSignalR";
import {useSelector} from "../redux/store";
import useAuth from "../hooks/useAuth";
import {addAlert, alertReceived, getAlerts, processAlert, updateAlert} from "../redux/slices/alerts";
import {cloneDeep, filter, first, slice, startCase} from "lodash";
import {noCase} from "change-case";
import useEnvironment from "../hooks/useEnvironment";

// ----------------------------------------------------------------------

const initialState = {
    ...defaultSettings,
    onChangeMode: () => {
    },
    onToggleMode: () => {
    },
    onChangeDirection: () => {
    },
    onChangeColor: () => {
    },
    onToggleStretch: () => {
    },
    onChangeLayout: () => {
    },
    onResetSetting: () => {
    },
    setColor: defaultPreset,
    colorOption: [],
};

const AlertsContext = createContext(initialState);

// ----------------------------------------------------------------------

AlertsProvider.propTypes = {
    children: PropTypes.node,
};

const mergeAlertTypes = [ALERT_TYPES.CHAT_MESSAGE, ALERT_TYPES.MAIL, ALERT_TYPES.SUPPORT_REQUEST]

function AlertsProvider({children}) {

    // chat, alert, notification
    const {user} = useAuth()
    const {organization} = useEnvironment();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const [userConnection, setUserConnection, removeUserConnection, userConnected, userLoading] = useSignalR('application');

    const [userConnectionSet, setUserConnectionSet] = useState(false);
    const {alerts, alertsCollected, unprocessedAlerts} = useSelector((state) => state.alerts);

    const userAlertReceived = useCallback((alert) => {
        dispatch(alertReceived(alert))
    }, [dispatch])

    useEffect(() => {

        if (unprocessedAlerts && unprocessedAlerts.length > 0 && user) {
            const alert = first(unprocessedAlerts)
            const {notifications} = user;
            const existingAlertTypeId = filter(alerts.allIds, (k) => {
                return alerts.byId[k].type === alert.type
            });

            if (ALERT_TYPES.CHAT_PARTICIPANT === alert.type && alert.participantId === user?._id) {
                const clonedAlerts = cloneDeep(unprocessedAlerts)
                clonedAlerts.shift();
                dispatch(processAlert(clonedAlerts))
                return
            }

            if (mergeAlertTypes.includes(alert.type) && existingAlertTypeId?.length >= 1) {
                const count = (alerts.byId[existingAlertTypeId]?.count || 1) + 1;
                dispatch(updateAlert({
                    ...alerts.byId[existingAlertTypeId],
                    isUnRead: true,
                    count
                }))
            } else {
                dispatch(addAlert({...alert, userId: user?._id, isUnRead: true}))
            }

            const processedAlerts = slice(unprocessedAlerts, 1);

            dispatch(processAlert(processedAlerts));

            let showAlert = false;
            switch (alert.type) {
                case ALERT_TYPES.JOB_REQUEST:
                    showAlert = !!notifications?.jobsNewRequestReceived
                    break;
                case ALERT_TYPES.NEW_BID:
                    showAlert = !!notifications?.newBidReceived || true; // TODO: add to the list of notifications for user to set
                    break;
                case ALERT_TYPES.JOB_REQUEST_ACCEPT:
                    showAlert = !!notifications?.jobsNewRequestReceived
                    break;
                case ALERT_TYPES.CHAT_MESSAGE:
                    showAlert = !!notifications?.chatsNewMessageReceived
                    break;
                case ALERT_TYPES.SUPPORT_REQUEST:
                    showAlert = !!notifications?.applicationSupport
                    break;
                case ALERT_TYPES.CONTRACT_CREATED:
                    // showAlert = !!notifications?.applicationSupport
                    showAlert = true;
                    break;
                case ALERT_TYPES.CONTRACT_UPDATED:
                    // showAlert = !!notifications?.applicationSupport
                    showAlert = true;
                    break;
                case ALERT_TYPES.DOCUMENT_IMPORTED:
                    // showAlert = !!notifications?.applicationSupport
                    showAlert = true;
                    break;
                case ALERT_TYPES.TEST:
                    console.log('Alert Test', {alert})
                    break;
                case ALERT_TYPES.CHAT_PARTICIPANT:
                    // showAlert = !!notifications?.applicationSupport
                    showAlert = alert.participantId !== user?._id;
                    break;
                default:
                    console.error("Unknown alert type", alert.type)
                    break;
            }

            if (enqueueSnackbar && showAlert) {
                enqueueSnackbar(`${alert.title || startCase(alert.type)} ${noCase(alert.description || '')}`, {
                    variant: "info",
                    key: alert.type,
                    preventDuplicate: true,
                    autoHideDuration: 3000,
                    anchorOrigin: {horizontal: "center", vertical: "top"}
                })
            }
        }
    }, [unprocessedAlerts, enqueueSnackbar, user, alerts, dispatch])

    useEffect(() => {
        if (user?._id && userConnection) {
            dispatch(getAlerts(user?._id));
        }
    }, [user, userConnection, dispatch]);

    useEffect(() => {
        if (alertsCollected && user?._id && organization && userAlertReceived && !userConnectionSet && !userLoading) {
            setUserConnectionSet(true);
            removeUserConnection(user?._id, userAlertReceived);
            setUserConnection(user?._id, userAlertReceived);
            removeUserConnection(organization, userAlertReceived);
            setUserConnection(organization, userAlertReceived);
        }
    }, [organization, alertsCollected, user, userAlertReceived, userConnectionSet, removeUserConnection, setUserConnection, userLoading]);

    return (
        <AlertsContext.Provider
            value={{
                alerts,
                userConnection,
                userConnected
            }}
        >
            {children}
        </AlertsContext.Provider>
    );
}

export {AlertsProvider, AlertsContext};
