import {useEffect} from "react";
import numeral from "numeral";
import PropTypes from 'prop-types';
import {each} from "lodash";
// @mui
import {createTheme, ThemeProvider, useTheme} from '@mui/material/styles';
// hooks
import useLocales from '../hooks/useLocales';
import {CURRENCIES, LANGS} from "../config";
// ----------------------------------------------------------------------

ThemeLocalization.propTypes = {
    children: PropTypes.node,
};

export default function ThemeLocalization({children}) {
    const defaultTheme = useTheme();
    const {currentLang} = useLocales();
    useEffect(() => {
        each(LANGS, (lang) => {
            try {
                numeral.register('locale', lang.value, {
                    delimiters: CURRENCIES[lang.currencyCode].delimiters,
                    abbreviations: {
                        thousand: 'k',
                        million: 'm',
                        billion: 'b',
                        trillion: 't'
                    },
                    ordinal: (number) => {
                        return number === 1 ? 'er' : 'ème';
                    },
                    currency: {
                        symbol: CURRENCIES[lang.currencyCode].symbol.default.display
                    }
                })
            } catch (e) {
                console.warn(e)
            }
        });
    }, [])

    const theme = createTheme(defaultTheme, currentLang.systemValue);

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
