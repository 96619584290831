import sink from './sink.json'
import create from './create'
import edit from './edit'
import supplier from './supplier'
import order from './order'
import editForm from './edit-form'


const translation = {...sink, create, edit, editForm, supplier, order}
export default translation
